import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'SyncAsset',
  components: {
    DatePicker,
    commonHelper,
    appStrings
  },

  watch: {
    currentPage() {
      this.getSyncTransactionAssetList();
    },
    perPage() {
      this.currentPage = 1;
      this.getSyncTransactionAssetList();
    }
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      isSuccess: false,
      responseMsg: null,
      showValueSetModal: false,
      SyncTransactionAssetHdrId:false,
      showSyncTransactionAssetModal:false,
      parent_value_set_id: null,
      showAddSyncTransactionAssetModal: false,
      searchCreatedBatch: false,
      legalEntity: {
        text: null,
        value: null
      },
      bookTypeCode: {
        value: null,
        text: null
      },
      transactionTypeCode: {
        value: null,
        text: null
      },
      locationTo: {
        value: null,
        text: null
      },
      datePlaceInService: null,
      status: {
        value: null,
        text: null
      },
      assetId: null,
      description: null,
      poNumber: null,
      invoiceNumber: null,
      inventoryItemCode: null,
      selectAllCheckBox: false,
      syncTransactionAssetData: [],
      syncTransactionAssetField: [
        {
          key: 'select'
        },
        {
          key: 'assetid',
          label: 'Asset Id'
        },
        {
          key: 'book_type_code'
        },
        {
          key: 'transaction_type_code'
        },
        {
          key: 'units_assigned'
        },
        {
          key: 'date_effective'
        },
        {
          key: 'transaction_date_entered'
        },
        {
          key: 'description'
        },
        {
          key: 'asset_type'
        },
        {
          key: 'major_category'
        },
        {
          key: 'minor_category'
        },
        {
          key: 'category_name'
        },
        {
          key: 'dpis'
        },
        {
          key: 'po_number'
        },
        {
          key: 'invoice_number'
        },
        {
          key: 'original_cost'
        },
        {
          key: 'transaction_units'
        },
        {
          key: 'from_fa_loc_id_name',
          label: 'FA Loc From'
        },
        {
          key: 'to_fa_loc_id_name',
          label: 'FA Loc To'
        },
        {
          key: 'from_hr_loc_id_name',
          label: 'HR Loc From'
        },
        {
          key: 'to_hr_loc_id_name',
          label: 'HR Loc To'
        },
        {
          key: 'current_units'
        },
        {
          key: 'current_units'
        },
        {
          key: 'tag_number'
        },
        {
          key: 'retirement_type_code'
        },
        {
          key: 'status'
        },
        {
          key: 'status_meaning',
          label: 'Status Meaning'
        },
        {
          key: 'error_msg'
        },
        {
          key: 'inventory_item_code'
        }
      ],
     
    };
  },
  validations: {
    legalEntity: {
      text: {
        required
      }
    },
    bookTypeCode: {
      text: {
        required
      }
    },
    transactionTypeCode: {
      text: {
        required
      }
    }
  },

  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.processAsset();
        }
        if (actionName === 'download') 
        {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'assets/getSyncTransactionAsset',
            'Transaction-Sync-Asset-Details',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getSyncTransactionAssetList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          legal_entity: this.legalEntity.value,
          book_type_code: this.bookTypeCode.value,
          transaction_type_code: this.transactionTypeCode.value,
          loaction_to: this.locationTo.value,
          dpis: commonHelper.formattedDate(this.datePlaceInService),
          status: this.status.value,
          assetId: this.assetId,
          description: this.description,
          po_number: this.poNumber,
          invoice_number: this.invoiceNumber,
          inventory_item_code: this.inventoryItemCode
        };
        this.loader = true;
        this.$store
          .dispatch('assets/getSyncTransactionAsset', this.payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              const result = resp.data.data.page;
              this.syncTransactionAssetData = result.map(data => {
                data.select = false;
                return data;
              });
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
   
    openValueSetModal(vsetCode) {
      this.chooseParentVsetId(vsetCode);
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
        }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    chooseParentVsetId(name) {
     switch (name) {
        case appStrings.VALUESETTYPE.ASSET_BOOK_TYPE:
          this.parent_value_set_id = this.legalEntity.value;
          break;
        default:
          this.parent_value_set_id = null;
      }
    },
    processAsset() {
      const unProcessedAssets = this.syncTransactionAssetData.filter(
        data => data.select
      );
      if (unProcessedAssets && unProcessedAssets.length > 0) {
        let allowedAssets = [];
        if (this.checkAssetsLength(50, unProcessedAssets)) {
          allowedAssets = commonHelper.removeExcessRecords(
            50,
            unProcessedAssets
          );
        } else {
          allowedAssets = unProcessedAssets;
        }
        const details = allowedAssets.map(data => {
          return {
            creation_by_name: data.creation_by_name,
            transaction_date_entered: data.transaction_date_entered,
            transaction_header_id: data.transaction_header_id,
            transaction_type_code: data.transaction_type_code,
            inventory_item_code: data.inventory_item_code,
            to_fa_loc_id_name: data.to_fa_loc_id_name,
            transaction_units: data.transaction_units,
            retirement_type_code: data.retirement_type_code,
            stg_header_id: data.stg_header_id,
            assetid: data.assetid,
            book_type_code: data.book_type_code,
            status: data.status,
            to_fa_loc_id: data.to_fa_loc_id,
            batch_id: data.batch_id,
            current_units: data.current_units,
            run_id: data.run_id,
            dpis: data.dpis,
            from_fa_loc_id: data.from_fa_loc_id,
            major_category: data.major_category,
            original_cost: data.original_cost,
            tag_number: data.tag_number,
            units_assigned: data.units_assigned,
            asset_type: data.asset_type,
            error_msg: data.error_msg,
            date_effective: data.date_effective,
            po_number: data.po_number,
            minor_category: data.minor_category,
            invoice_number: data.invoice_number,
            current_cost: data.current_cost,
            status_meaning: data.status_meaning,
            description: data.description,
            creation_date: data.creation_date,
            creation_by: data.creation_by,
            le_id: data.le_id,
            category_name: data.category_name
          };
        });
        const payload = {
          asset_details: details
        };
        this.loader = true;
        this.$store
          .dispatch('assets/postSyncTransactionProcessAsset', payload)
          .then(response => {
            this.loader = false;
            this.dismissCountDown = 5;
            if (response.status === 201) {
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },

    rowSelected() {},
    validateCheckBox(row) {
      if (row.request_num) {
        if (!/e/i.test(row.book_type_code)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    checkAssetsLength(permittedLength, arr) {
      if (arr.length > permittedLength) {
        this.dismissCountDown = 5;
        this.isSuccess = false;
        this.responseMsg = 'Only 50 records processed!';
        return true;
      }
    },

    chooseValueset(name) {
      switch (name) {
        case 'legal_entity':
          this.openValueSetModal(appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST);
          break;
        case 'book_type_code':
          this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_BOOK_TYPE);
          break;
        case 'transaction_type_code':
          this.openValueSetModal(appStrings.VALUESETTYPE.TRANSACTION_TYPE_CODE);
          break;
        case 'to_fa_location':
          this.openValueSetModal(appStrings.VALUESETTYPE.TO_FA_LOCATION);
          break;
        case 'status':
          this.openValueSetModal(appStrings.VALUESETTYPE.POST_TO_ORACLE_STATUS);
          break;
      }
    },

    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
          this.legalEntity = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.bookTypeCode = {
            value: null,
            text: null
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_BOOK_TYPE:
          this.bookTypeCode = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };

          break;
        case appStrings.VALUESETTYPE.TRANSACTION_TYPE_CODE:
          this.transactionTypeCode = {
            value: item.value_code,
            text: item.value_meaning
          };
          break;
        case appStrings.VALUESETTYPE.TO_FA_LOCATION:
          this.locationTo = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.POST_TO_ORACLE_STATUS:
          this.status = {
            value: item.value_code,
            text: item.value_meaning
          };
          break;
      }
    },
    clear() {
      this.legalEntity = {
        text: null,
        value: null
      };
      this.bookTypeCode = {
        value: null,
        text: null
      };
      this.transactionTypeCode = {
        value: null,
        text: null
      };
      this.locationTo = {
        value: null,
        text: null
      };
      this.datePlaceInService = null;
      this.status = {
        value: null,
        text: null
      };
      this.assetId = null;
      this.description = null;
      this.poNumber = null;
      this.invoiceNumber= null;
      this.inventoryItemCode = null;
      this.syncTransactionAssetData = [];
      this.totalRows =null;
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case 'legal_entity':
          this.legalEntity = {
            value: null,
            text: null
          };
          this.bookTypeCode = {
            value: null,
            text: null
          };
          break;
        case 'book_type_code':
          this.bookTypeCode = {
            value: null,
            text: null
          };

          break;
        case 'transaction_type_code':
          this.transactionTypeCode = {
            value: null,
            text: null
          };
          break;
        case 'to_fa_location':
          this.locationTo = {
            value: null,
            text: null
          };
          break;
        case 'status':
          this.status = {
            value: null,
            text: null
          };
          break;
      }
    }
    
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
